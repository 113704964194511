import React from "react";
import Layout from "../components/Layout";
import QuickActions from "../components/QuickActions";
import "./document.sass";
import "../Styles.sass";
import { Link } from "gatsby";
import arrow from "../images/arrow_up.png";
import { checkoutFactory } from "../helpers";

export default function Document({ pageContext }) {
  const title = pageContext.title;
  const subtitle = pageContext.subtitle;
  const checklist = pageContext.checklist;
  const price = pageContext.price;
  const description = pageContext.description;
  const cta = pageContext.cta;
  const cid = pageContext.cid;
  const metaDescription = pageContext.metaDescription;

  return (
    <Layout title={title} description={metaDescription}>
      <div id="header-box">
        <div id="left" className="prestation-column">
          <h5>Contrats et documents</h5>
          <h2>{title}</h2>
          <p>{subtitle}</p>
        </div>
        <div id="right" className="prestation-column">
          <ul>
            {checklist.map((l) => (
              <li>{l}</li>
            ))}
            <li id="price-bullet">
              {price.includes("Gratuit") ? price : "CHF " + price + ".-"}
            </li>
          </ul>
        </div>
      </div>
      <div id="prestation-description">
      <div id="prestation-cta-holder">
          {price.includes("Gratuit") ? (
            <a href={"/" + cid + ".zip"} download>
              {cta} <img src={arrow} alt="arrow-icon"></img>
            </a>
          ) : (
            <Link
              to="/checkout"
              state={checkoutFactory(
                "contract",
                price,
                title,
                "Contrats et documents",
                subtitle,
                checklist,
                cid
              )}
            >
              {cta} <img src={arrow} alt="arrow-icon"></img>
            </Link>
          )}
        </div>
        <p>{description}</p>
        
      </div>
      <div id="prestation-actions">
        <QuickActions></QuickActions>
      </div>
    </Layout>
  );
}
